html {
    font-family: "REM", sans-serif;
    background-color: #313131ff;
    color: white;
    font-size: 125%;
    line-height: 2 !important;
}

::-webkit-scrollbar {
    display: none;
}


h1 {
    line-height: 1.3 !important;
    margin: 0 !important;
    text-align: left;
}

@media screen and (max-width: 1000px){
    h1 {
        text-align: center;
        word-break: break-word;
    }
}

h2 {
    margin:0 !important;
    line-height: 1.3 !important;
}

a {
    color: cadetblue;
}

:root {
    --zapf-meenz-red: #c1172d;
}

/* latin */
@font-face {
    font-family: 'REM';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(../fonts/REM.ttf);
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


@media only screen and (max-width: 800px) {
    html{
        font-size: 100%;
    }
}
